import { useState } from "react";
import { Link } from "react-router-dom";
import { getOrCreateUsername, updateUsername } from "./UserState";

interface MenuProps {
    title: string;
}

export const Menu = (props:MenuProps) => {

    const [menuOpen, setMenuOpen] = useState(false);    
    const [username, setUsername] = useState(getOrCreateUsername);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    return (
        <div id="menuContainer">
            <header className="menu"><button onClick={() => toggleMenu()}>☰&nbsp;<span style={menuOpen ? { display:'none'} : {display: 'inline'}}>🟩🟥🟨⬛</span></button> {props.title}</header>
            <div id="menu" style={menuOpen ? { display: 'block' } : { display: 'none' }}>                
                    <div>🟩 <Link to="/">HOME</Link></div>
                    <div>🟥 <Link to="/create">NEW GAME</Link></div>
                    <div>🟨 <Link to="/history">PREVIOUS GAMES</Link></div>
                    <div>⬛ <Link to="/stats">STATS</Link></div>
                    <div>🟦 USER: <input onChange={(e) => setUsername(e.target.value)} name="username" value={username || ''}/>
                                                          <button onClick={() => {if (username) { updateUsername(username); }}}>save</button>                        
                    </div>
            </div>
        </div>
    )
};