
import { useState } from 'react';
import { Keyboard, Keys } from './Keyboard';
import { words as wordsFlat } from './words/WordsEN';
import { useNavigate } from 'react-router-dom';
import { encodeIndex } from './Code';
import { createGame } from './UserState';
import { Menu } from './Menu';

const allWords:Array<[string, number]> = wordsFlat.map((w:string, ix:number) => [w, ix]);

export const CreateGame = () => {

    const [letters, setLetters] = useState([' ', ' ', ' ', ' ', ' ']);
    const [index, setIndex] = useState(0);
    const [words, setWords] = useState(allWords);
    const navigate = useNavigate();


    const onSelect = (wordIndex:number) => {
        let code = encodeIndex(wordIndex);
        createGame(code);
        navigate("/share/" + code);
    };

    const onPress = (letter:string) => {

        let l = [...letters];

        if (letter >= 'A' && letter <= 'Z') {            
            l[index] = letter;
            setLetters(l);

            if (index < 4) {
                setIndex(index + 1);
            }
        }
        if (letter === Keys.DELETE) {
            let ix = index;
            if (l[index] === ' ' && ix > 0) {
                ix -= 1;
            }
            l[ix] = ' ';
            setLetters(l);
            setIndex(ix);
        }

        let w = allWords.filter(([w, ix]) => {
            for (let i = 0; i < 5; i++) {
                if (l[i] === ' ') {
                    continue;
                }
                if (l[i] !== w.charAt(i).toUpperCase()) {
                    return false;
                }
            }
            return true;
        });
        setWords(w);
    };

    let filterEmpty = true;
    letters.forEach((l:string) => { if (l !== ' ') { filterEmpty = false; }});


    return (
        <div className="container">
            <Menu title="Create Game"/>
            <p>
            first, select letter position            
            </p>
            <div id="input">
                {letters.map((l, ix) => (
                    <div key={ix} onClick={() => setIndex(ix)} className={
                        `letter ${index === ix ? "selected" : ""}
                        ${l !== ' ' ? "letter-green" : ""}`
                    }>{l}</div>
                ))}                
            </div>
            <p>
            type to filter words
            </p>
            <Keyboard onPress={onPress}/>
            <p>{words.length} total words{filterEmpty && (<> - type to show them</>)}
            {!filterEmpty && words.length > 0 && (<> - tap to select</>)}</p>
             {!filterEmpty && (
            <div id="list">
                {words.map(([w, ix]) => (
                    <button onClick={() => onSelect(ix)} key={ix}>{w.toUpperCase()}</button>
                ))}
            </div>)}
       </div>
    );
}
