import React, { useEffect, useRef } from 'react';

type KeyProps = {
    onPress: (arg0:string) => void,
    letter: string,
    key: string,
    keyState: KeyState,
}


export enum KeyState {
    STANDARD,
    GREEN,
    YELLOW,
    DARKGRAY,
}

const stateMap = {
    [KeyState.GREEN]: "key-green",
    [KeyState.YELLOW]: "key-yellow",
    [KeyState.DARKGRAY]: "key-darkgray",
    [KeyState.STANDARD]: "",
}

const Key = (props: KeyProps) => {
    let classes = ["key"];
    if (props.keyState in stateMap) {
        classes.push(stateMap[props.keyState]);
    }
    return (
        <button className={classes.join(" ")} onClick={() => props.onPress(props.letter)}>
            <div className="keyText">{props.letter}</div>
        </button>
    )
};

type KeyboardProps = {
    onPress: (arg0:string) => void,
    keyStates?: Map<string, KeyState>,
};

export enum Keys {
    DELETE = "⌫",
    ENTER = "↵",
}

function useKeyEventListener(handler:(arg0:KeyboardEvent) => void){
    // Create a ref that stores handler
    const savedHandler = useRef<(arg0:KeyboardEvent) => void>();
  
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
      savedHandler.current = handler;
    }, [handler]);
  
    useEffect(
      () => {
  
        // Create event listener that calls handler function stored in ref
        const eventListener = (e:KeyboardEvent) => { 
            savedHandler.current && savedHandler.current(e);
        };
  
        // Add event listener
        document.body.addEventListener('keyup', eventListener);
  
        // Remove event listener on cleanup
        return () => {
            document.body.removeEventListener('keyup', eventListener);
        };
      },
      [] // Re-run if eventName or element changes
    );
  };

export const Keyboard = (props: KeyboardProps) => {
    const rows = ["QWERTYUIOP", "ASDFGHJKL", Keys.ENTER + "ZXCVBNM" + Keys.DELETE];

    let keyStates = props.keyStates || new Map();
    
    useKeyEventListener((e:KeyboardEvent) => {
        console.log('event triggered');
            if (e.key >= 'a' && e.key <= 'z') {
            props.onPress(e.key.toUpperCase());
            }
            if (e.key === 'Backspace') {
            props.onPress(Keys.DELETE);
            }
            if (e.key === 'Enter') {
            props.onPress(Keys.ENTER);
            }
    });

    const getKeyState = (letter:string) => {
        return keyStates.get(letter) || KeyState.STANDARD;
    }

    let rs = [];
    for (let i = 0; i < rows.length; i++) {
        let ks = [];
        for (let j = 0; j < rows[i].length; j++) {
            ks.push(React.createElement(Key, {
                onPress: () => props.onPress(rows[i][j]),
                letter: rows[i][j],
                key: 'key-' + rows[i][j],
                keyState: getKeyState(rows[i][j]),
            }
            ));
        }
        rs.push(ks);
    }

    return (
        <div className="keyboard">
            <div className="keyboardRow">{rs[0]}</div>
            <div className="keyboardRow">{rs[1]}</div>
            <div className="keyboardRow">{rs[2]}</div>
        </div>
    );
};