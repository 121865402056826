import { useParams } from 'react-router';
import { KeyState, Keyboard, Keys } from './Keyboard';
import { decodeIndex } from './Code';
import { wordSet, words } from './words/WordsEN';
import { useEffect, useState } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useLocalStorage from './LocalStorage';
import { updateStatsLoss, updateStatsWin } from './Stats';
import { PlayedGame, allPlayedGames, getGameUsername, isGameStarted, saveGuess, setSingleGameState, singleGameState, startGame } from './UserState';
import { Menu } from './Menu';
import { LetterState, gameSymbols, letterStates } from './GameUtils';

export enum GameState {
    IN_PROGRESS = 1,
    FAILED = 2,
    WON = 3,
}

enum GuessState {
    IN_PROGRESS,
    UNKNOWN_WORD
}

const maxTries:number = 6;
const wordLen:number = 5;

interface LetterProps {
    letter: string,
    state: LetterState,
};

const styleMap = {
    [LetterState.Unknown]: 'letter-unknown',
    [LetterState.Correct]: 'letter-green',
    [LetterState.Misplaced]: 'letter-yellow',
    [LetterState.Incorrect]: 'letter-gray',
    [LetterState.InProgress]: undefined,    
    [LetterState.Selected]: 'letter-gray',
}

const Letter = (props: LetterProps) => {
    let styles:Array<string|undefined> = ["letter"];
    if (props.letter !== ' ') {
        styles.push(styleMap[props.state]);
    }

    /*
    const marginAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

    useEffect(() => {
        console.log(props.letter + ' in state ' + props.state + ', ' + marginAnim.current);        
        if (props.state == LetterState.Unknown) {
            return;
        }
        Animated.sequence([
            Animated.timing(marginAnim, {
                toValue: 0,
                duration: 0,
                useNativeDriver: false,
            }),
            Animated.timing(marginAnim, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: false,
            }),
        ]).start(); 
    }, [marginAnim, props.state]);
    */

    return (
        <div className={styles.join(' ')}>
            {props.letter}
        </div>
    );
};

interface LetterRowProps {
    letters: Array<[string, LetterState]>,
    resetUnknownWord: () => void,
  }

export const LetterRow = (props:LetterRowProps) => {    
        return (
            <div className="letter-row-container">
                <div className="letter-row">                
                    {props.letters.map(([letter, state], ix) => (
                        <Letter state={state} key={ix.toString()} letter={letter}/>
                    ))}
                </div>
            {props.letters[0][1] === LetterState.Unknown && (
                    <div onClick={() => props.resetUnknownWord()}
                         className="letter-row-notice">⚠️ unknown word</div>
                )}
            </div>
        )
  }

interface StateSetters {
    addGuess: (guess:string) => void,
    setGameState: (newState:GameState) => void,
    setStarted: () => void,
}

function useGameState(code?: string):[PlayedGame, StateSetters] {

    const [state, setState] = useState<PlayedGame>(singleGameState(code));
    
    if (!code) {
        return [state, { addGuess: () => {}, setGameState: () => {}, setStarted: () => {}}];
    }

    let setters = {
        addGuess: (guess: string) => {
            console.log('Adding guess ' + guess);
            state.guesses.push(guess);
            console.log(state);
            state.lastUpdated = new Date();
            setState(state);
            setSingleGameState(code, state);
        },
        setGameState: (newState: GameState) => {
            state.state = newState;
            setState(state);
            setSingleGameState(code, state);
        },
        setStarted: () => {
            state.started = new Date();
            state.lastUpdated = new Date();
            setState(state);
            setSingleGameState(code, state);
        }
    }

    return [state, setters];
};


export const PlayGame = () => {
  
    let { code } = useParams();
    let navigate = useNavigate();

    const [ gameState, setGameState ] = useGameState(code);
    const [ guessState, setGuessState ] = useState(GuessState.IN_PROGRESS);    
    const [ currentGuess, setCurrentGuess ] = useState('');

    const [ copiedMessage, setCopiedMessage] = useState<string|null>(null);

    console.log(code, gameState);

    let secretWord:string = '';

    if (code !== undefined) {
        let wordIndex = decodeIndex(code);
        if (wordIndex >= 0 && wordIndex < words.length) {
            secretWord = words[wordIndex].toUpperCase();            
        }
    }

    useEffect(() => {
        if (code !== undefined) {
            if (!isGameStarted(code)) {   
                startGame(code);             
                setGameState.setStarted();                
                console.log('Marked game {} as started.', code);
            }
        } else {
            navigate('/');
        }
    }, [code]); // only ever run once

    if (!code || secretWord === '') {
        return (<></>);
    }

    const resetUnknownWord = () => {
        setCurrentGuess('');
        setGuessState(GuessState.IN_PROGRESS);
    }

    const onPress = (l:string) => {
        let current:string = currentGuess;
        let currentGuesses:Array<string> = [...gameState.guesses];
        if (l === Keys.DELETE) {
            if (current.length > 0) {
                current = current.substring(0, current.length-1);
                setCurrentGuess(current);
                setGuessState(GuessState.IN_PROGRESS);
            }
        } else if (l === Keys.ENTER) {
            if (guessState === GuessState.UNKNOWN_WORD) {
                resetUnknownWord();
                return;
            }
            if (current.length === wordLen) {                                
                if (wordSet.has(current.toLowerCase())) {                    
                    currentGuesses.push(currentGuess);
                    if (code !== undefined) {
                        saveGuess(code, current);
                    }
                    setGameState.addGuess(currentGuess);
                    setCurrentGuess('');
                } else {
                    // TODO: implement effect to show word not in vocab
                    setGuessState(GuessState.UNKNOWN_WORD);
                }
            }
            if (current === secretWord) {
                setGameState.setGameState(GameState.WON);
                updateStatsWin(currentGuesses.length);
            } else if (currentGuesses.length === maxTries) {
                setGameState.setGameState(GameState.FAILED);
                updateStatsLoss();
            }        
        } else {
            if (current.length < wordLen) {
                current += l;
                setCurrentGuess(current);
            }
        }    
    };

    const resultMessage = () => {        
        let resultTexts = [
            "None",  // 0-index
            "Excellent!",
            "Impressive!",
            "Three frags left!",
            "Two frags left!",
            "One frag left!",
            "You are tied for the lead!",
        ];

        let message = gameState.state === GameState.FAILED ?
            "💩 You have lost the lead! 💩" :
            "🎉 " + resultTexts[gameState.guesses.length] + " 🎉";
        return message.toUpperCase();
    }

    const gameResultBlurb = () => {
        let num = gameState.guesses.length.toString();        
        if (gameState.state === GameState.FAILED) {
            num = 'X';
        }            

        let blurb = `GWOR ${num}/${maxTries}`;
        let username = getGameUsername(code);
        if (username !== null && username?.length > 0) {
            blurb += ` by ${username}`;
        }
        blurb += `: ${resultMessage()}`;
        let symbols = gameSymbols(secretWord, gameState.guesses);
        
        return `${blurb}\n${symbols}`;
    }

    const copy = () => {        
        let plain = gameResultBlurb();

        if (typeof ClipboardItem !== "undefined") {            
            const text = new Blob([plain], { type: "text/plain" });
            const data = new ClipboardItem({ "text/plain": text });
            navigator.clipboard.write([data]);
        } else {
            // Fallback using the deprecated `document.execCommand`.
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
            const cb = (e:any) => {                
                e.clipboardData.setData("text/plain", plain);
                e.preventDefault();
            };
            document.addEventListener("copy", cb);
            document.execCommand("copy");
            document.removeEventListener("copy", cb);
            console.log('Copied with legacy method.');
        }
        setCopiedMessage("Copied to clipboard");
    }

    const share = () => {
        
        if (navigator.share !== undefined) {
            try {                
                navigator.share({
                    title: 'GWOR Game Result',
                    text: gameResultBlurb(),
                });
            } catch (err) {
                console.log('Failed to share: ' + err);
            }
        } else {
            copy();
        }
    };

    const getKeyStates = () => {
        let m = new Map<string, KeyState>();
        gameState.guesses.forEach((g) => {
            for (let i = 0; i < g.length; i++) {
                if (g[i] === secretWord[i]) {
                    m.set(g[i], KeyState.GREEN);
                } else if (!m.has(g[i]) && secretWord.indexOf(g[i]) >= 0) {
                    m.set(g[i], KeyState.YELLOW);
                } else if (!m.has(g[i])) {
                    m.set(g[i], KeyState.DARKGRAY);
                }
            }
        });
        return m;
    }

    let letterRows = [];        
    for (let i = 0; i < maxTries; i++) {
        let isEditedRow = i === gameState.guesses.length;
        let word = '';
        if (i < gameState.guesses.length) {
            word = gameState.guesses[i];
        }
        if (i === gameState.guesses.length) {
            word = currentGuess;
        }
        let letters:Array<[string, LetterState]> = [];
        let ls = letterStates(secretWord, word);
        for (let j = 0; j < wordLen; j++) {            
            let [letter, letterState] = ls[j];            
            if (i >= gameState.guesses.length) {                
                if (isEditedRow && guessState === GuessState.UNKNOWN_WORD) {
                    letterState = LetterState.Unknown;
                } else {
                    letterState = LetterState.InProgress;
                }
            }
            letters.push([letter, letterState]);
        }
        letterRows.push(React.createElement(LetterRow, {
          letters: letters,
          key: i.toString(),
          resetUnknownWord: () => resetUnknownWord(),
        }));
    }

    let keyboard;
    let message;
    if (gameState.state === GameState.IN_PROGRESS) {        
        keyboard = <Keyboard onPress={onPress} keyStates={getKeyStates()}></Keyboard>;
    } else {                
        message = (
        <>        
        {gameState.state === GameState.FAILED && (            
            <div className="lost-word">
                <div>{secretWord[0]}</div>
                <div>{secretWord[1]}</div>
                <div>{secretWord[2]}</div>
                <div>{secretWord[3]}</div>
                <div>{secretWord[4]}</div>
            </div>
        )}
        <p>{resultMessage()}</p>
        {gameState.state === GameState.WON && getGameUsername(code) !== null && (
            <p>game created by <strong>{getGameUsername(code)}</strong></p>
        )}
        <p className="shareArea">
            <button className="redButton" onClick={() => share()}>SHARE</button>
            <button className="grayButton" onClick={() => copy()}>COPY</button>
            <button className="yellowButton" onClick={() => navigate("/stats")}>STATS</button>
            <button className="greenButton" onClick={() => navigate("/share/" + code)}>FORWARD</button>
            <button className="redButton" onClick={() => navigate("/live/" + code)}>WATCH LIVE</button>
        </p>
        <p>{copiedMessage}</p>
        </>
        );
    }


    return (
      <div className="container">
        <Menu title="Can You Guess?"/> 
        <div className="letter-rows">
            {letterRows}
        </div>
        {keyboard}        
        {message}
      </div>
    );
}