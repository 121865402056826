import { Menu } from "./Menu";

interface Dictionary<T> {
    [Key: string]: T;
}

interface StatsData {
    wins: number;
    losses: number;
    streak: number;
    maxStreak: number;
    gamesCreated: number;
    gameStats: Dictionary<number>;
}

const GWOR_STATS_KEY = "gwor-stats";

export const readStats = () => {
    let statsJSON = localStorage.getItem(GWOR_STATS_KEY);
    let stats;
    if (statsJSON === null) {
        stats = {
            wins: 0,
            losses: 0,
            streak: 0,
            gamesCreated: 0,
            gameStats: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
            }
        }
    } else {
        stats = JSON.parse(statsJSON);
        if (!stats.maxStreak) {
            stats.maxStreak = 0;
        }
    }
    return stats;
}

const writeStats = (stats:StatsData) => {
    localStorage[GWOR_STATS_KEY] = JSON.stringify(stats);
}

export const updateStatsWin = (n:number) => {
    let stats = readStats();
    stats.wins += 1;
    stats.streak += 1;
    if (stats.maxStreak < stats.streak) {
        stats.maxStreak = stats.streak;
    }
    if (n in stats.gameStats) {
        stats.gameStats[n] += 1;
    } else {
        stats.gameState[n] = 1;
    }
    writeStats(stats);
}

export const updateStatsLoss = () => {
    let stats = readStats();
    stats.losses += 1;
    stats.streak = 0;
    writeStats(stats);
}

export const Stats = () => {
    let stats = readStats();

    let relativeGameStats:Dictionary<number> = {};
    let sum = 0;
    for (let key in stats.gameStats) {
        sum += stats.gameStats[key];
    }
    for (let key in stats.gameStats) {
        relativeGameStats[key] = Math.round((stats.gameStats[key] / sum) * 100);
    }

    console.log(stats);

    return (
        <div className="container stats">
            <Menu title="Statistics"/>
            <h3>Overall</h3>
            <p>
            ⏳ Total <span className="num">{stats.wins+stats.losses}</span>
            💪🏼 Streak <span className="num">{stats.streak}</span></p>
            <p>
            🎉 Win <span className="num">{stats.wins}</span>
            💩 Lose <span className="num">{stats.losses}</span>
            </p>
            <h3>Guesses</h3>
            <div className="game-stats">
                <table><tbody>
                    {[1,2,3,4,5,6].map((num) => (
                        <tr><td><span className="num">{num}</span></td><td>{stats.gameStats[num] > 0 ? stats.gameStats[num] : "-"}</td><td className="bar"><div style={{ width: relativeGameStats[num] + "%" }}></div></td></tr>
                    ))}
                </tbody></table>
            </div>
        </div>
    )
}