import { useNavigate } from "react-router-dom";
import { Menu } from "./Menu";
import { allPlayedGames, getCreatedGamesStats, getGameUsername, playedGameFromDTO } from "./UserState";
import { decodeWord, gameSymbols } from "./GameUtils";
import { GameState } from "./PlayGame";
import { useState } from "react";

enum Mode {
    PLAYED,
    CREATED
}

interface PlayedGames {
    gameCode: string,
    startedDate?: Date,
    guesses: any,
    gameFinished: boolean,
    gameLost: boolean,
    title: string,
    symbols: string,
    creator: string,
}

export const History = () => {

    const navigate = useNavigate();
    const [mode, setMode] = useState(Mode.PLAYED);    
    const [createdGames, setCreatedGames] = useState([]);
    const [showCreatedGame, setShowCreatedGame] = useState<string|null>(null);

    let playedGames:(PlayedGames|null)[] = [];
    if (mode === Mode.PLAYED) {
        // This is not an array any more!!!
        let games = allPlayedGames();
        playedGames = Object.keys(games).map((gameCode) => {
            let game = playedGameFromDTO(games[gameCode]);
            let word = decodeWord(game.code);
            if (word === undefined) {
                return null;
            }
            word = word.toUpperCase();
            
            return {
                gameCode: game.code,
                startedDate: new Date(game.started),
                guesses: game.guesses,
                gameFinished: game.state !== GameState.IN_PROGRESS,
                gameLost: game.state === GameState.FAILED,
                title: game.state !== GameState.IN_PROGRESS ? word : '?????',
                symbols: gameSymbols(word, game.guesses),
                creator: game.creator || getGameUsername(game.code) || '',
            };
        }).sort((a:any, b:any) => {
            if (a === null || !a.startedDate) {
                return 1;
            }
            if (b === null || !b.startedDate) {
                return -1;
            }
            return a.startedDate > b.startedDate ? -1 : 1;
        });
    }

    const createdGamesMode = () => {
        getCreatedGamesStats().then((response) => {
            setCreatedGames(response.createdGames.sort((a:any, b:any) => {
                return new Date(a['created']) > new Date(b['created']) ? -1 : 1;                                
            }));
        });
        setMode(Mode.CREATED);
    }

    const padNumber = (n:number) => {
        if (n < 10) {
            return '0' + n.toString();
        } else {
            return n.toString();
        }
    }

    const formatDate = (d:Date) => {
        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();

        let hour = padNumber(d.getHours());
        let minutes = padNumber(d.getMinutes());

        return day + '. ' + month + '. ' + year + ' ' + hour + ':' + minutes;
    };

    const toggleShowCreatedGame = (code:string) => {
        if (showCreatedGame === code) {
            setShowCreatedGame(null);
        } else {
            setShowCreatedGame(code);
        }
    };

    let detailsWord:string="";
    if (showCreatedGame !== null) {
        detailsWord = decodeWord(showCreatedGame) || "";
    }

    return (
        <div className="container">
            <Menu title="Previous Games"/>
            <div id="buttons">
                <button className={mode === Mode.CREATED ? "grayButton" : "greenButton"} onClick={() => setMode(Mode.PLAYED)}>Games Played</button>
                <button className={mode === Mode.PLAYED ? "grayButton" : "greenButton"} onClick={() => createdGamesMode()}>Games Created</button>
            </div>
            <div id="gameList">
            {playedGames.length > 0 && playedGames.map(g => {                
                if (g === null) {
                    return null;
                }
                return (
                    <div key={g.gameCode} className="gameCard">                        
                        <div className="leftSide">
                            <span className="gameTitle">{g.title} {g.gameLost ? "X" : g.guesses.length}/6</span>
                            <span className="gameCode">creator: {g.creator}</span>
                            <span className="gameCode">code: {g.gameCode}</span>
                            <span className="gameDate">{g.startedDate && formatDate(g.startedDate)}</span>
                            <div className="historyButtons">
                                <button className={g.gameFinished ? "details" : "continue"}
                                    onClick={() => navigate("/game/" + g.gameCode)}>{!g.gameFinished ? "Continue" : "Details"}</button>
                                <button className="continue"
                                    onClick={() => navigate("/live/" + g.gameCode)}>live</button>
                            </div>
                        </div>
                        <div className="rightSide">
                            {g.symbols}
                        </div>
                    </div>
                );
            })}
            {createdGames.length && createdGames.map((game:any) => (
                <div key={game.gameCode} className="gameCard">                        
                <div className="leftSide">
                    <span className="gameTitle">{decodeWord(game.gameCode)?.toUpperCase()}</span>
                    <span className="gameCode">code: {game.gameCode}</span>                    
                    <span className="gameDate">created: {formatDate(new Date(game.created))}</span>                                        
                    <div className="historyButtons">
                        <button className="details"
                                onClick={() => toggleShowCreatedGame(game.gameCode)}>{showCreatedGame !== game.gameCode ? "details" : "close"}</button>
                        <button className="continue"
                                onClick={() => navigate("/live/" + game.gameCode)}>live</button>
                    </div>
                </div>
                <div className="rightSide">
                    {showCreatedGame !== game.gameCode && (
                    <>
                    <span className="gameAverage">{game.gameStates.length > 0 && 
                        "AVG: " + (game.gameStates.map((gs:any) => gs.guesses.length).reduce((acc:any, cur:any) => acc+cur, 0) / game.gameStates.length).toFixed(1)}
                    </span>
                    {game.gameStates.map((gs:any) => (
                        <div key={gs.username}>{gs.username}</div>
                    ))}
                    </>
                    )}
                    {showCreatedGame === game.gameCode && game.gameStates.map((gs:any) => {
                        
                        if (gs.guesses.length === 0) {
                            return (<div key={gs.username} className="gameState">
                                        <span className="username">{gs.username}</span>
                                        <p>No guesses yet.</p>
                                    </div>)
                        }

                        // Still thinking.
                        let result = "💬";

                        if (detailsWord === gs.guesses[gs.guesses.length-1]) {
                            // Won.
                            result = "🎉 " + gs.guesses.length + " / " +  6;
                        } else if (gs.guesses.length === 6) {
                            // Lost.
                            result = "💩 X / 6";
                        }

                        return (
                        <div key={gs.username} className="gameState">
                            <span className="username">{gs.username}</span>
                            <div>{result}</div>
                            <div>
                                {gameSymbols(detailsWord, gs.guesses)}
                            </div>
                        </div>
                    )})}
                </div>
            </div>                
            ))}
            {playedGames.length < 1 && createdGames.length < 1 && (
                <>
                <p>You did not play any games yet!</p>
                <button className="previousGames">Create a new game</button>
                </>
            )}
            </div>
        </div>
    )
};