import { useNavigate } from "react-router-dom"

export const Home = () => {

    let navigate = useNavigate();

    return (
    <div className="container">
      <header>      
        <div className="letter letter-green">G</div>
        <div className="letter letter-red">W</div>
        <div className="letter letter-yellow">O</div>
        <div className="letter letter-gray">R</div>
      </header>
      <div id="game-description">
        <p>This is a word guessing game, where</p>
        <p className="emphasis">YOU CHOOSE THE GAME WORD</p>
        <p>and then</p>
        <p className="emphasis">YOUR FRIENDS GUESS!</p>
      </div>
      <button onClick={() => navigate("/create")} className="createGame">
        <p>Share a new game</p>
        <div className="newGameSymbolContainer">
          <div className="newGameSymbol">
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
            <div className="row">
              <div></div><div></div><div></div><div></div><div></div>
            </div>
          </div>         
          <div className="arrow">
          ⮕
          </div>
          <div className="phone">
          📱
          </div>
        </div>        
      </button>    
      <p>OR</p>
      <button onClick={() => navigate("/history")} className="previousGames">
      📅 See previous games
      </button>
    </div>
  )};
