import { Keyboard } from "./Keyboard"

export const Test = () => {

    const onPress = (l:string) => {

    } 

    return (
        <div className="container">
            <div className="keyboard-small">
                <Keyboard onPress={onPress}/>
            </div>
            <div className="keyboard-mid">
                <Keyboard onPress={onPress}/>
            </div>
            <div className="keyboard-large">
                <Keyboard onPress={onPress}/>
            </div>
            <div className="keyboard-max">
                <Keyboard onPress={onPress}/>
            </div>
        </div>
    )
}