import { useNavigate, useParams } from "react-router-dom";
import { Menu } from "./Menu"
import { useEffect, useState } from "react";
import { getSingleCreatedGamesStats } from "./UserState";
import { decodeWord, gameSymbols } from "./GameUtils";
import { GameState } from "./PlayGame";


export const Live = () => {
    let { code } = useParams();
    let [ gameState, setGameState ] = useState<any>({});
    let [ gameCreated, setGameCreated ] = useState<Date>();

    let navigate = useNavigate();

    useEffect(() => {
        if (code == null) {
            return;
        }
        let nonNullCode = code;
        let secretWord = decodeWord(code);
        let load = () => {
            if (document.hasFocus()) {
                getSingleCreatedGamesStats(nonNullCode).then((response) => {
                    setGameCreated(new Date(response.createdGames[0].created));
                    let data = response.createdGames[0].gameStates.map((g:any) => {
                        if (secretWord === g.guesses[g.guesses.length-1]) {
                            // Won.
                            g['result'] = GameState.WON;                            
                        } else if (g.guesses.length === 6) {
                            // Lost.
                            g['result'] = GameState.FAILED;
                        } else {
                            g['result'] = GameState.IN_PROGRESS;
                        }
                        g['winner'] = false;
                        return g;
                    });                    
                    data.sort((a:any, b:any) => {
                        if (a['result'] !== b['result']) {
                            if (a['result'] === GameState.WON) {
                                return -1;
                            }
                            if (b['result'] === GameState.WON) {
                                return 1;
                            }
                            if (a['result'] === GameState.IN_PROGRESS) {
                                return -1;
                            }
                            if (b['result'] === GameState.IN_PROGRESS) {
                                return 1;
                            }
                        }
                        let diff1 = new Date(a['lastUpdated']).getTime() - new Date(a['started']).getTime();
                        let diff2 = new Date(b['lastUpdated']).getTime() - new Date(b['started']).getTime();

                        if (diff1 < diff2) {
                            return -1;
                        }
                        if (diff2 < diff1) {
                            return 1;
                        }
                        return 0;
                    });
                    if (data.length > 0 && data[0]['result'] === GameState.WON) {
                        data[0]['winner'] = true;
                    }                    
                    setGameState(data);
                });
            }
        };
        load();        
        let interval = setInterval(load, 3000);
        return () => {
            clearInterval(interval);
        }
    }, [code]);

    if (code === undefined || code == null) {
        navigate("/");
        return (<></>);
    }

    let secretWord = decodeWord(code);

    const dateDiff = (createdDate:Date) => {
        let diff = (new Date()).getTime() - createdDate.getTime();
        
        const day = 86400 * 1000;
        const hour = 3600 * 1000;

        let days = Math.floor(diff / day);
        let hours = Math.floor((diff % day) / hour);
        let minutes = Math.floor((diff % hour) / 60000);

        if (days > 0) {
            return days + ((days > 1) ? " days " : " day ") + "ago";
        }

        let hs = "";
        if (hours > 0) {
            hs = hours + ((hours > 1) ? " hours" : " hour") + ", ";
        }

        hs += minutes + " min ago";

        return hs;
    }

    const timeDiff = (dateFrom:string, dateTill:string) => {        
        let diff = (new Date(dateTill)).getTime() - (new Date(dateFrom)).getTime();
        
        const day = 86400 * 1000;
        const hour = 3600 * 1000;

        let days = Math.floor(diff / day);
        let hours = Math.floor((diff % day) / hour);
        let minutes = Math.floor((diff % hour) / 60000);
        let seconds = Math.floor((diff % 60000) / 1000);

        if (days > 0) {
            return "1+ days";
        }
        
        if (hours > 0) {
            return "1+ hours"
        }

        return minutes + " min, " + seconds + " sec";
    }

    return (
        <div className="container">
            <Menu title="Live"/>
            <div>
            {gameCreated && (
                <p>created {dateDiff(gameCreated)}</p>
            )}
            {gameState && gameState.length === 0 && (
                <p>No Players Started Yet</p>
            )}
            {gameState && gameState.length > 0 && (
                <p>{gameState.length} players</p>
            )}
            </div>
            <div className="liveGames">
            {gameState && gameState.map && gameState.map((gs:any) => {
                        
                if (gs.guesses.length === 0) {
                    return (<div key={gs.username} className="gameState">
                                <span className="username">{gs.username}</span>
                                <p>no guesses yet</p>
                            </div>)
                }

                // Still thinking.
                let result = "💬";
                
                if (secretWord === gs.guesses[gs.guesses.length-1]) {
                    // Won.
                    result = "🎉 " + gs.guesses.length + " / " +  6;
                } else if (gs.guesses.length === 6) {
                    // Lost.
                    result = "💩 X / 6";
                }

                return (
                <div key={gs.username} className="gameState">
                    <span className="username">{gs.winner ? "👑": ""} {gs.username}</span>
                    <div>{result}</div>
                    <div>{timeDiff(gs.started, gs.lastUpdated)}</div>
                    <div className="symbols">
                        {secretWord && gameSymbols(secretWord, gs.guesses)}
                    </div>
                </div>
            )})}
            </div>
        </div>
    )   
}