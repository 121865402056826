import { decodeWord } from './GameUtils';
import { GameState } from './PlayGame';
import { words } from './words/WordsEN';

const GWOR_UUID = "gwor-uuid";
const GWOR_CHANGES = "gwor-changes";
const GWOR_GAME_STARTED = "gwor-game-started-";
const GWOR_GUESSES = "gwor-guesses-";
const GWOR_USERNAME = "gwor-username";
const GWOR_GAME_CREATOR = "gwor-game-creator-";

enum ChangeType {
    CREATE_GAME = 1,    // uses param1 for game code
    START_GAME = 2,     // param1: game code
    GAME_GUESS = 3,     // param1: game code, param2: guess
    CREATE_USER = 4,    // no params
    UPDATE_USERNAME = 5,    // param1: username
}

class Change {
    changeType:ChangeType;
    param1?: string;
    param2?: string;

    constructor(type:ChangeType, p1?:string, p2?:string) {
        this.changeType = type;
        this.param1 = p1;
        this.param2 = p2;
    }
}

class SyncDTO {
    uuid:string;
    changes?:Change[];
    
    constructor(uuid:string, changes?:Change[]) {
        this.uuid = uuid;
        this.changes = changes;
    }
}


export const getUserID = () => {
    return localStorage.getItem(GWOR_UUID);
};

const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    // Time in microseconds since page-load or 0 if unsupported
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

export const GWOR_PLAYED_GAMES = "gwor-played-games";

export interface PlayedGameDTO {
    code: string,
    guesses: string[],
    started: string,
    lastUpdated: string,
    creator: string,
}

export interface PlayedGame {
    code: string,
    guesses: string[],
    started: Date,
    lastUpdated: Date,
    state: GameState,
    creator: string,
}

const computeGameState = (code:string, guesses:string[]) => {
    let secretWord = decodeWord(code);
    if (!secretWord) {
        return GameState.FAILED;
    }

    if (guesses.length > 0 && guesses[guesses.length - 1] === secretWord) {
        return GameState.WON;
    }

    if (guesses.length < 6) {
        return GameState.IN_PROGRESS;
    }

    return GameState.FAILED;
}

export const playedGameFromDTO = (dto:PlayedGameDTO) => {
    let a:PlayedGame = {
        code: dto.code,
        guesses: dto.guesses,
        started: new Date(dto.started),
        lastUpdated: new Date(dto.lastUpdated),
        state: computeGameState(dto.code, dto.guesses),
        creator: dto.creator
    };

    return a;    
}

export const allPlayedGames = () => {

    let playedGames = localStorage.getItem(GWOR_PLAYED_GAMES);
    if (playedGames !== null) {
        return JSON.parse(playedGames);
    }

    let games:{ [key: string]: PlayedGameDTO } = {};
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(GWOR_GUESSES)) {
            let code = key.substring(GWOR_GUESSES.length);             
            games[code] = {
                code: code,
                guesses: JSON.parse(localStorage.getItem(key) || '') || [],
                started: localStorage.getItem(GWOR_GAME_STARTED + code) || '',
                lastUpdated: (new Date()).toUTCString(),                
                creator: localStorage.getItem(GWOR_GAME_CREATOR + code) || '',
            };            
        }
    });
    localStorage.setItem(GWOR_PLAYED_GAMES, JSON.stringify(games));
    return games;
};

export const singleGameState = (code?:string) => {
    let defaultState:PlayedGame = {
        code: code || '',
        guesses: [],
        started: new Date(),
        lastUpdated: new Date(),
        state: GameState.IN_PROGRESS,
        creator: ''
    };
    if (!code) {
        return defaultState;
    }
    let games = allPlayedGames();
    if (games[code]) {
        return playedGameFromDTO(games[code]);
    }
    return defaultState;
};

export const setSingleGameState = (code:string, state:PlayedGame) => {
    let games = allPlayedGames();
    let dto:PlayedGameDTO = {
        code: state.code,
        guesses: state.guesses,
        started: state.started.toUTCString(),
        lastUpdated: state.lastUpdated.toUTCString(),
        creator: state.creator,
    };
    games[code] = dto;
    localStorage.setItem(GWOR_PLAYED_GAMES, JSON.stringify(games));
};

const createRandomUsername = () => {
    let w1:string = words[Math.floor(Math.random() * words.length)];
    let w2 = words[Math.floor(Math.random() * words.length)];

    return w1[0].toUpperCase() + w1.substring(1) + w2[0].toUpperCase() + w2.substring(1);
}

export const ensureUser = () => {
    let userID = getUserID();
    if (userID === null) {
        if (crypto.randomUUID !== undefined) {
            userID = crypto.randomUUID();
        } else {
            console.warn("Fallback to alternate UUID generation");
            userID = generateUUID();
        }
        console.log('Setting userID to ', userID);
        localStorage.setItem(GWOR_UUID, userID);
        console.log('#1 Updating username after initial user creation');
        createUser(userID, getOrCreateUsername());
    } else {
        let uID = userID;        
        if (getUsername() == null) {
            updateUsername(createRandomUsername());
        }
        sync().then(
            (user) => {            
                if (user === null) {
                    console.log('Returned user is null.');
                    let username = getUsername();
                    if (username == null) {
                        username = createRandomUsername();
                    }
                    createUser(uID, username);
                } else {
                    console.log(user);
                    if (!user.username) {
                        return;
                    }
                    localStorage.setItem(GWOR_USERNAME, user.username);
                }
            },
            (reason) => {
                console.log(reason);
                if (getUsername() === null) {
                    console.log('#2 Updating username after failed request');
                    updateUsername(createRandomUsername());
                }
            });
    }
};

export const createGame = async (gameCode:string) => {    
    enqueueChange(new Change(ChangeType.CREATE_GAME,gameCode));
    sync();
}

export const startGame = async (gameCode:string) => {    
    let newGame:PlayedGame = {
        code: gameCode,
        creator: getGameUsername(gameCode) || '',
        guesses: [],
        started: new Date(),
        lastUpdated: new Date(),
        state: GameState.IN_PROGRESS,
    };
    setSingleGameState(gameCode, newGame);    
    enqueueChange(new Change(ChangeType.START_GAME, gameCode));
    sync();
}

export const isGameStarted = (gameCode:string) => {    
    let games = allPlayedGames();
    if (!games[gameCode]) {
        return false;
    }
    return true;
}

export const saveGuess = async (gameCode:string, guess:string) => {
    enqueueChange(new Change(ChangeType.GAME_GUESS, gameCode, guess));
    sync();
}

export const createUser = (uuid:string, username:string) => {
    enqueueChange(new Change(ChangeType.CREATE_USER), /*first*/true);
    enqueueChange(new Change(ChangeType.UPDATE_USERNAME, username));
    sync();
};

export const getOrCreateUsername = () => {
    let username = localStorage.getItem(GWOR_USERNAME);
    if (username === null) {
        username = createRandomUsername();
        localStorage.setItem(GWOR_USERNAME, username);
    }
    return username;
}

export const getUsername = () => {
    return localStorage.getItem(GWOR_USERNAME);
}

export const updateUsername = (username:string) => {    
    localStorage.setItem(GWOR_USERNAME, username);
    enqueueChange(new Change(ChangeType.UPDATE_USERNAME, username));    
    sync();
}

export const enqueueChange = async (c:Change, first:boolean = false) => {
    let changes = [];
    if (localStorage.getItem(GWOR_CHANGES) !== null) {
        changes = JSON.parse(localStorage[GWOR_CHANGES]);
    }
    if (first) {
        changes.unshift(c);
    } else {
        changes.push(c);
    }
    localStorage.setItem(GWOR_CHANGES, JSON.stringify(changes));    
};

interface GameUsername {
    gameCode: string,
    username: string,
}

export const getGameUsername = (gameCode?:string) => {
    return localStorage.getItem(GWOR_GAME_CREATOR + gameCode);
}

const updateGameUsernames = (gu:Array<GameUsername>) => {
    for (const gameUsername of gu) {
        if (gameUsername.username) {
            localStorage.setItem(GWOR_GAME_CREATOR + gameUsername.gameCode, gameUsername.username);
        }
    }
};

export const getCreatedGamesStats = async () => {
    let userID = getUserID();
    const response = await fetch(process.env.REACT_APP_API_URL + '/sync/created/' + userID, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });    
    return response.json();
};

export const getSingleCreatedGamesStats = async (code:string) => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/sync/live/' + code, {
        method: "GET",
        cache: "no-cache",
        headers: {
            "Content-Type": "application/json",
        },
    });    
    return response.json();
};

const updatePlayedGames = (gameStates:any) => {
    let games:{ [key: string]: PlayedGameDTO } = {};
    gameStates.forEach((g:any) => {        
        games[g.code] = {
            code: g.code,            
            creator: g.username,
            guesses: g.guesses,
            lastUpdated: g.lastUpdated,
            started: g.started
        };
    });
    localStorage.setItem(GWOR_PLAYED_GAMES, JSON.stringify(games));
};

export const sync = async () => {
    let changes = [];
    if (localStorage.getItem(GWOR_CHANGES) !== null) {
        changes = JSON.parse(localStorage[GWOR_CHANGES]);
    }    
    let userID = getUserID();
    if (userID === null) {
        console.log("User not initialized; skipping sync.");
        return;
    }
    let syncDTO = new SyncDTO(userID, changes);
    if (process.env.REACT_APP_API_URL === undefined) {
        console.log('Environment configured incorrectly, cannot access REACT_APP_API_URL');
        return;
    }
    // Optimistically clear these changes for now.
    localStorage.removeItem(GWOR_CHANGES);
    try {
        const response = await fetch(process.env.REACT_APP_API_URL + '/sync/user', {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(syncDTO),
        });        
        const responseData = await response.json();
        updateGameUsernames(responseData.gameCreatorUsernames);
        updatePlayedGames(responseData.user.gameStates);
        return responseData.user;
    } catch (error) {
        // Restore all the failed changes (eg, if no internet).
        // Note that new changes might have been enqueued due to async nature.
        let changes = [];
        if (localStorage.getItem(GWOR_CHANGES) !== null) {
            changes = JSON.parse(localStorage[GWOR_CHANGES]);
        }
        let previousChanges = syncDTO.changes;
        previousChanges?.push(...changes);
        localStorage.setItem(GWOR_CHANGES, JSON.stringify(previousChanges));
        return Promise.reject(new Error('Request failed: ' + error));
    }
};