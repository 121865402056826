import React, { useEffect } from 'react';

import { Home } from './Home';
import { ErrorPage } from './Error';
import { CreateGame } from './CreateGame';
import { PlayGame } from './PlayGame';
import { ShareGame } from './ShareGame';
import { History } from './History';

import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Stats } from './Stats';
import { ensureUser } from './UserState';
import { Test } from './Test';
import { Live } from './Live';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "create",
    element: <CreateGame />,
    errorElement: <ErrorPage />,
  },
  {
    path: "game/:code",
    element: <PlayGame/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "share/:code",
    element: <ShareGame/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "stats",
    element: <Stats/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "history",
    element: <History/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "live/:code",
    element: <Live/>,
    errorElement: <ErrorPage />,
  },
  {
    path: "test",
    element: <Test/>,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  
  useEffect(() => {    
    ensureUser();    
  }, []);

  return (
    <div className="App">
      <React.StrictMode>
        <RouterProvider router={router} />
      </React.StrictMode>
    </div>
  );
}

export default App;
