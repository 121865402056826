import { useNavigate, useParams } from "react-router-dom";
import { decodeIndex } from "./Code";
import { words } from './words/WordsEN';
import { useState } from "react";
import { Menu } from "./Menu";

export const ShareGame = () => {
    let { code } = useParams();
    let navigate = useNavigate();
    let [copiedMessage, setCopiedMessage] = useState('');

    let error = false;

    code = code || 'TX6Q';

    let index = decodeIndex(code);
    let word;
    if (index < 0 || index >= words.length) {
        word = 'ERROR';
        error = true;
    } else {
        word = words[index];
    }

    let letters = word.split('').map(l=>l.toUpperCase());

    const plainText = (code:string|undefined) => {
        return `🟩🟥🟨⬛ GWOR Game code: ${code}\nhttps://gwor.turtip.com/game/${code}`;
    }

    const copy = () => {        
        let plain = plainText(code);

        if (typeof ClipboardItem !== "undefined") {            
            const text = new Blob([plain], { type: "text/plain" });
            const data = new ClipboardItem({ "text/plain": text });
            navigator.clipboard.write([data]);
        } else {
            // Fallback using the deprecated `document.execCommand`.
            // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
            const cb = (e:any) => {                
                e.clipboardData.setData("text/plain", plain);
                e.preventDefault();
            };
            document.addEventListener("copy", cb);
            document.execCommand("copy");
            document.removeEventListener("copy", cb);
            console.log('Copied with legacy method.');
        }
        setCopiedMessage('Copied to clipboard');
    };

    const share = () => {
        if (navigator.share !== undefined) {
            try {
                navigator.share({
                    title: 'GWOR',
                    text: '🟩🟥🟨⬛ Play a GWOR game!',
                    url: 'http://gwor.turtip.com/game/' + code,
                });
            } catch (err) {
                console.log('Failed to share: ' + err);
            }
        } else {
            copy();
        }
    }
    return (
        <div className="container">
            <Menu title="Share"/>
            <h1>You selected</h1>
            <header>
                {letters.map((l, ix) => (
                    <div key={ix} className="letter letter-green">{l}</div>
                ))}
            </header>
            {error === false && (
                <>
                <p>Game code is <strong>{code}</strong></p>
                <h2>Share with friends!</h2>
                <p className="shareArea">                                         
                    <button className="shareButton redButton" onClick={() => share()}>SHARE</button>
                    <button className="shareButton grayButton" onClick={() => copy()}>COPY</button>
                    <button className="createButton yellowButton" onClick={() => navigate("/create")}>CREATE NEW</button>
                    <button className="playButton greenButton" onClick={() => navigate("/game/" + code)}>PLAY</button>
                    <br/>
                    <button className="playButton redButton" onClick={() => navigate("/live/" + code)}>WATCH LIVE</button>
                </p>
                <p>{copiedMessage}</p>
                </>
            )}
            {error && (
                <h2>Invalid game code {code}.</h2>
                )}
        </div>
    );
};