import { decodeIndex } from "./Code";
import { words } from "./words/WordsEN";

export enum LetterState {
    Unknown = 0,
    Correct = 1,
    Misplaced = 2,
    Incorrect = 3,
    InProgress = 4,    
    Selected = 5,
}

export const decodeWord = (code:string) => {
    let index = decodeIndex(code);

    if (index < 0 || index >= words.length) {
        return undefined;
    }

    return words[index].toUpperCase();
}

export const letterStates = (secretWord:string, guess:string, wordLen:number=5) => {
    let ls:[string, LetterState][] = [];
    let correct = new Set<number>();
    let totalLetters = new Map<string, number>();
    let correctLetters = new Map<string, number>();
    let misplacedLetters = new Map<string, number>();

    guess += '     '; // guaranteed to have a length of 5

    // First, find the corrects.
    for (let i = 0; i < wordLen; i++) {
        if (guess[i] === secretWord[i]) {
            correct.add(i);
            correctLetters.set(guess[i], (correctLetters.get(guess[i]) || 0)+1);
        }
        totalLetters.set(secretWord[i], (totalLetters.get(secretWord[i]) || 0)+1);
    }
    for (let i = 0; i < wordLen; i++) {
        if (correct.has(i)) {
            ls.push([guess[i], LetterState.Correct]);
            continue;
        }
        if (secretWord.indexOf(guess[i]) >= 0) {
            let numCorrect = correctLetters.get(guess[i]) || 0;                                
            let numMisplaced = misplacedLetters.get(guess[i]) || 0;
            let numTotal = totalLetters.get(guess[i]) || 0;

            if (numCorrect+numMisplaced < numTotal) {
                misplacedLetters.set(guess[i], numMisplaced+1);
                ls.push([guess[i], LetterState.Misplaced]);
                continue;
            }
        }
        ls.push([guess[i], LetterState.Incorrect]);
    }
    return ls;
}


export const gameSymbols = (gameWord:string, guesses:string[], maxTries:number=6, wordLen:number=5) => {
    let symbols = '';
    for (let i = 0; i < maxTries; i++) {
        if (i >= guesses.length) {
            break;
        }
        // 🟩🟥🟨⬛
        let ls = letterStates(gameWord, guesses[i]);
        for (let j = 0; j < wordLen; j++) {
            let [, state] = ls[j];
            if (state === LetterState.Correct) {
                symbols += '🟩';
            } else if (state === LetterState.Misplaced) {
                symbols += '🟨';
            } else if (i === 5) {
                symbols += '🟥';
            } else {
                symbols += '⬛';
            }
        }

        symbols += '\n';
    }

    return symbols;
};