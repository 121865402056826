/**
 * Set of functions to encode an up to 5bit number as a series of
 * letters and numbers.
 * 
 * The number is split into 5bit parts. A 5bit salt (0-24) is randomly
 * chosen and these parts are XORed with it.
 * The resulting code consists of uppercase letter representation of the
 * salt, followed by three uppercase leters or numbers 0-9, depending on
 * the result of each XOR.
 */

// Up to 5bit numbers.
const encodeInt = (n:number) => {
    if (n > 25) {
        n -= 25;
        return String.fromCharCode(48 + n);
    }
    return String.fromCharCode(65 + n);
};

export const encodeIndex = (ix:number) => {
    let s1 = Math.floor(Math.random() * 25);
    let s2 = Math.floor(Math.random() * 25);
    let s3 = Math.floor(Math.random() * 25);
    let b1 = (ix & 0b11111) ^ s1;
    let b2 = ((ix >> 5) & 0b11111) ^ s2;
    let b3 = ((ix >> 10) & 0b11111) ^ s3;

    return String.fromCharCode(65+s1) +
        String.fromCharCode(65+s2) +
        String.fromCharCode(65+s3) +
        encodeInt(b1) +
        encodeInt(b2) +
        encodeInt(b3);
};

const decodeChar = (s:string, ix:number) => {
    let c = s.charCodeAt(ix);
    if (c > 64) {
        return c - 65;
    }
    return (c - 48) + 25;
};

export const decodeIndex = (s:string) => {
    if (s.length !== 4 && s.length !== 6) {
        return -1;
    }
    if (s.length === 4) {
        return decodeIndexLegacy(s);
    }
    let s1 = s.charCodeAt(0) - 65;
    let s2 = s.charCodeAt(1) - 65;
    let s3 = s.charCodeAt(2) - 65;
    let b1 = decodeChar(s, 3);
    let b2 = decodeChar(s, 4);
    let b3 = decodeChar(s, 5);

    return ((b3 ^ s3) << 10) + 
           ((b2 ^ s2) << 5) +
           (b1 ^ s1);
};

export const decodeIndexLegacy = (s:string) => {
    let salt = s.charCodeAt(0) - 65;
    let b1 = decodeChar(s, 1);
    let b2 = decodeChar(s, 2);
    let b3 = decodeChar(s, 3);

    return ((b3 ^ salt) << 10) + 
           ((b2 ^ salt) << 5) +
           (b1 ^ salt);
};